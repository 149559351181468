import { Listbox, Transition } from "@headlessui/react";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { usePopper } from "react-popper";
import { FieldError } from "../Error/FieldError";
import { Icon } from "../Icon";

export type SelectMenuOption<O = unknown> = O & {
  id: string;
  subValue?: string;
  disabled?: boolean;
  value: React.ReactNode;
  optGroupHeader?: boolean;
  selectedLabel?: React.ReactNode;
};

export type SelectMenuProps = {
  labelText?: string | React.ReactNode;
  required?: boolean;
  hasAdd?: boolean;
  hasAttachedLabel?: boolean;
  description?: React.ReactNode;
  register?: FieldValues;
  initialIndex?: number;
  listWidth?: string;
  listAlignment?: "left" | "right";
  listVerticalAlignment?: "top" | "bottom";
  error?: string;
  disabled?: boolean;
  className?: string;
  border?: string;
  darkMode?: boolean;
  showListOnly?: boolean;
  externalOpen?: boolean;
  heightPx?: number;
  addOnSelect?: boolean;
  placeholder?: string; // Can only appear when selectedLabel is an empty string.
};

type Props<O> = SelectMenuProps & {
  options: SelectMenuOption<O>[];
  onSelect: (option: SelectMenuOption<O>) => void;
};

export function SelectMenu<O>({
  labelText,
  options,
  className,
  externalOpen = false,
  onSelect: onSelectProp,
  required = false,
  description,
  initialIndex = 0,
  error,
  border,
  addOnSelect = false,
  showListOnly = false,
  hasAdd = false,
  listWidth = "w-auto",
  listAlignment = "left",
  listVerticalAlignment = "bottom",
  darkMode = false,
  disabled = false,
  heightPx = 38,
  placeholder,
}: Props<O>) {
  const [refButton, setRefButton] = useState<HTMLElement | null>(null);
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(refElement, popperElement, {
    placement:
      listVerticalAlignment === "bottom"
        ? listAlignment === "right"
          ? "bottom-end"
          : "bottom-start"
        : listAlignment === "right"
        ? "top-end"
        : "top-start",
  });

  const [selected, setSelected] = useState<SelectMenuOption<O> | undefined>(
    options[initialIndex] ?? options[0]
  );

  // If the initialIndex changes, update the selected option.
  // This lets us control the selected option from outside the component.
  useEffect(() => {
    setSelected(options[initialIndex] ?? options[0]);
  }, [initialIndex, options]);

  const onSelect = (option: SelectMenuOption<O>) => {
    if (!option.optGroupHeader) {
      setSelected(option);
      onSelectProp(option);
    }
  };

  useEffect(() => {
    if (externalOpen) refButton?.click();
  }, [externalOpen, refButton]);

  useEffect(() => {
    update?.();
  }, [options, update]);

  return (
    <div className={clsx("flex flex-col gap-0.5", className)}>
      <Listbox
        ref={setRefElement}
        value={selected}
        onChange={onSelect}
        disabled={disabled || options.length === 0}
      >
        {({ open }) => (
          <div className="flex flex-col gap-1">
            {labelText && (
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                {labelText}
                {required && (
                  <span className="ml-1 text-gray-800 text-sm font-semibold">
                    *
                  </span>
                )}
              </Listbox.Label>
            )}
            <div>
              <Listbox.Button
                ref={setRefButton}
                className={clsx(
                  "bg-white relative border border-gray-300 rounded-md shadow-sm",
                  "pl-3 pr-10 sm:text-sm w-full flex items-center justify-between",
                  border,
                  hasAdd && "rounded-r-none",
                  disabled ? "opacity-60 cursor-not-allowed" : "cursor-pointer",
                  darkMode && "!border-slate-600 !bg-slate-500 text-white",
                  showListOnly &&
                    "!h-0 !w-0 overflow-hidden opacity-0 pointer-events-none"
                )}
                style={{ height: `${heightPx}px` }}
                onClick={() => update?.()}
              >
                {!showListOnly && (
                  <>
                    <span
                      className={clsx(
                        "block truncate",
                        options.length === 0
                          ? "italic text-gray-500"
                          : selected?.selectedLabel === "" &&
                              placeholder &&
                              "italic text-gray-300"
                      )}
                    >
                      {options.length === 0
                        ? "No options available"
                        : selected
                        ? selected.selectedLabel ||
                          (selected.selectedLabel === "" && placeholder) ||
                          selected.value
                        : "No option selected"}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <Icon
                        icon="select"
                        size={5}
                        color={darkMode ? "text-white" : "text-gry-600"}
                      />
                    </span>
                  </>
                )}
              </Listbox.Button>

              <div
                style={styles.popper}
                {...attributes.popper}
                ref={setPopperElement}
                className={clsx("z-20")}
              >
                <Transition
                  show={showListOnly ? externalOpen : open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={clsx(
                      "absolute min-w-[10ch] overflow-hidden shadow-xl mt-1 rounded-md",
                      listAlignment === "left" ? "left-0" : "right-0",
                      listWidth,
                      listVerticalAlignment === "top" &&
                        "-translate-y-[calc(100%+6px)]"
                    )}
                  >
                    <div
                      className={clsx(
                        "relative z-[200 max-h-[500px] border rounded-md",
                        "text-base overflow-auto sm:text-sm h-fit",
                        darkMode
                          ? "bg-slate-700 border-slate-900"
                          : "bg-gray-50 border-gray-200",
                        getScrollbarStyle(darkMode ? "dark" : "gray")
                      )}
                    >
                      {options.map((option, i) => (
                        <Listbox.Option
                          key={option.id}
                          className={({ active }) =>
                            clsx(
                              "cursor-pointer select-none relative py-2 pl-3",
                              addOnSelect ? "pr-3" : "pr-9",
                              darkMode
                                ? active
                                  ? "bg-slate-950"
                                  : "odd:bg-slate-800"
                                : active
                                ? "bg-blue-600"
                                : "odd:bg-gray-100/80",

                              active && selected && "bg-blue-700",
                              option.optGroupHeader &&
                                "font-semibold bg-slate-400/80 odd:bg-slate-400/80 text-white cursor-default"
                            )
                          }
                          value={option}
                          disabled={option.disabled || option.optGroupHeader}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex flex-col relative">
                                <div
                                  className={clsx(
                                    option.disabled &&
                                      "opacity-95 text-slate-300 text-left",
                                    selected ? "font-semibold" : "font-normal",
                                    darkMode
                                      ? "text-white"
                                      : active
                                      ? "text-white"
                                      : "text-gray-900",
                                    option.optGroupHeader && "text-white",
                                    "flex items-center truncate ml-[2px]",
                                    addOnSelect
                                      ? "w-full justify-between mr-0 pr-0"
                                      : "mr-6"
                                  )}
                                >
                                  {option.value}
                                  {i !== 0 && addOnSelect && (
                                    <Icon
                                      icon="add"
                                      size={5}
                                      className="ml-4"
                                      color={
                                        darkMode
                                          ? "text-blue-600"
                                          : active
                                          ? "text-white"
                                          : "text-blue-600"
                                      }
                                    />
                                  )}
                                </div>
                                {option.subValue && (
                                  <span
                                    className={clsx(
                                      selected ? "font-normal" : "font-light",
                                      active ? "text-white" : "text-gray-500",
                                      "block truncate"
                                    )}
                                  >
                                    {option.subValue}
                                  </span>
                                )}
                              </div>
                              {selected && !addOnSelect ? (
                                <span
                                  className={clsx(
                                    "absolute inset-y-0 right-0 flex items-center mr-2"
                                  )}
                                >
                                  <div
                                    className={clsx(
                                      "flex justify-center items-center w-[14px] h-[14px] rounded-full",
                                      darkMode && "bg-white"
                                    )}
                                  >
                                    <Icon
                                      icon="check"
                                      size={5}
                                      color={
                                        darkMode
                                          ? "text-blue-600"
                                          : active
                                          ? "text-white"
                                          : "text-blue-600"
                                      }
                                    />
                                  </div>
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </div>
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          </div>
        )}
      </Listbox>
      <FieldError msg={error} />
      {description && (
        <div
          className={clsx("ml-1", typeof description === "string" && "text-sm")}
        >
          {description}
        </div>
      )}
    </div>
  );
}
