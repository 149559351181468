import React, { createContext, useContext, useEffect, useState } from "react";
import {
  TW_2XL_BREAKPOINT,
  TW_LG_BREAKPOINT,
  TW_MD_BREAKPOINT,
  TW_SM_BREAKPOINT,
  TW_XL_BREAKPOINT,
} from "./constants";

// TailwindCSS Breakpoints start at:
// xs: 0px
// sm: 640px
// md: 768px
// lg: 1024px
// xl: 1280px
// 2xl: 1536px

const initialState = {
  breakpoint: "",
  screenWidth: 0,
  screenHeight: 0,

  isXsScreen: false,
  isSmScreen: false,
  isMdScreen: false,
  isLgScreen: false,
  isXlScreen: false,
  is2XlScreen: false,

  isLessThanMdScreen: false,
  isLessThanLgScreen: false,
  isLessThanXlScreen: false,
  isLessThan2XlScreen: false,
};

interface LayoutContextType {
  breakpoint: string;
  screenWidth: number;
  screenHeight: number;

  isXsScreen: boolean;
  isSmScreen: boolean;
  isMdScreen: boolean;
  isLgScreen: boolean;
  isXlScreen: boolean;
  is2XlScreen: boolean;

  isLessThanMdScreen: boolean;
  isLessThanLgScreen: boolean;
  isLessThanXlScreen: boolean;
  isLessThan2XlScreen: boolean;
}

const LayoutContext = createContext<LayoutContextType>(initialState);

export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [breakpoint, setBreakpoint] = useState<string>("");
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [screenHeight, setScreenHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getCurrentBreakpoint = () => {
      if (screenWidth >= TW_2XL_BREAKPOINT) {
        return "2xl";
      } else if (screenWidth >= TW_XL_BREAKPOINT) {
        return "xl";
      } else if (screenWidth >= TW_LG_BREAKPOINT) {
        return "lg";
      } else if (screenWidth >= TW_MD_BREAKPOINT) {
        return "md";
      } else if (screenWidth >= TW_SM_BREAKPOINT) {
        return "sm";
      } else {
        return "xs";
      }
    };

    setBreakpoint(getCurrentBreakpoint());
  }, [screenWidth]);

  const isLessThanMdScreen = breakpoint === "xs" || breakpoint === "sm";
  const isLessThanLgScreen = isLessThanMdScreen || breakpoint === "md";
  const isLessThanXlScreen = isLessThanLgScreen || breakpoint === "lg";
  const isLessThan2XlScreen = isLessThanXlScreen || breakpoint === "xl";

  const contextValue: LayoutContextType = {
    breakpoint,
    screenWidth,
    screenHeight,

    isXsScreen: breakpoint === "xs",
    isSmScreen: breakpoint === "sm",
    isMdScreen: breakpoint === "md",
    isLgScreen: breakpoint === "lg",
    isXlScreen: breakpoint === "xl",
    is2XlScreen: breakpoint === "2xl",

    isLessThanMdScreen,
    isLessThanLgScreen,
    isLessThanXlScreen,
    isLessThan2XlScreen,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
