import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";
import { AttendanceStatusPillButton } from "./AttendanceStatusPillButton";
import { statusButtonOptions } from "./constants";

type Props = {
  status: CohortSessionStudentAttendanceStatus;
  loading?: boolean;
  updateStudentAttendance: (
    status: CohortSessionStudentAttendanceStatus
  ) => void;
};

export const AttendanceStatusButtonsPanel = ({
  status,
  loading = false,
  updateStudentAttendance,
}: Props) =>
  statusButtonOptions.map((optionStatus, i) => (
    <AttendanceStatusPillButton
      loading={loading}
      status={optionStatus}
      key={`${i}-${status}`}
      isSelected={optionStatus == status}
      updateStudentAttendance={updateStudentAttendance}
    />
  ));
