import { gql } from "@apollo/client";
import {
  SubstituteTeacherSelector_IsAttendanceFragment,
  User,
} from "@generated/graphql";
import { getEngagementISAttendanceStatusText } from "@utils/labels";
import { SelectMenu, SelectMenuOption } from "components/shared";
import { useMemo } from "react";

const NONE_SELECTED_ID = "NONE_SELECTED";

SubstituteTeacherSelector.fragments = {
  engagementInstructionalSupportAttendance: gql`
    fragment SubstituteTeacherSelector_ISAttendance on EngagementInstructionalSupportAttendance {
      id
      status
      user {
        id
        fullName
      }
    }
  `,
};

type Props = {
  isAttendanceEntries: SubstituteTeacherSelector_IsAttendanceFragment[];
  selectedSubstituteId: User["id"] | null;
  onSelectSubstitute: (substituteId?: User["id"]) => void;
};

export function SubstituteTeacherSelector({
  isAttendanceEntries,
  selectedSubstituteId,
  onSelectSubstitute,
}: Props) {
  const [substituteOptions, selectedSubstituteIndex]: [
    SelectMenuOption[],
    number,
  ] = useMemo(() => {
    const substituteOptions: SelectMenuOption[] = [
      {
        id: NONE_SELECTED_ID,
        value: "Select a Substitute",
      },
      ...isAttendanceEntries.map((isAttendanceEntry) => ({
        id: isAttendanceEntry.user.id,
        value: isAttendanceEntry.user.fullName,
        subValue: `Status: ${getEngagementISAttendanceStatusText(
          isAttendanceEntry.status
        )}`,
      })),
    ];
    return [
      substituteOptions,
      substituteOptions.findIndex(({ id }) => id === selectedSubstituteId),
    ];
  }, [isAttendanceEntries, selectedSubstituteId]);

  return (
    <div className="flex flex-col gap-y-1">
      <SelectMenu
        listVerticalAlignment="top"
        options={substituteOptions}
        onSelect={({ id }) => {
          if (id === NONE_SELECTED_ID && selectedSubstituteId != null) {
            onSelectSubstitute(undefined); // Remove the substitute.
          } else if (selectedSubstituteId !== id) {
            onSelectSubstitute(id); // Select a new substitute.
          }
        }}
        initialIndex={selectedSubstituteIndex}
        listAlignment="right"
      />
    </div>
  );
}
