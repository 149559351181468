import { YesNoSomewhat } from "@generated/graphql";
import { getYesNoSomewhatText } from "@utils/labels";
import { assertUnreachable } from "@utils/types";
import clsx from "clsx";
import { PillButton } from "../Buttons";

const selectedStyle = "border-2 text-white";
const defaultStyle = "border border-gray-300 bg-white text-gray-700";
const options = [YesNoSomewhat.Yes, YesNoSomewhat.Somewhat, YesNoSomewhat.No];

type Props = {
  required?: boolean;
  className?: string;
  value: YesNoSomewhat | null;
  onChange: (value: YesNoSomewhat | null) => void;
};

export const YesNoSomewhatInput = ({
  value,
  className,
  required = false,
  onChange,
}: Props) => (
  <div className={clsx("flex items-center gap-x-2", className)}>
    {options.map((option) => {
      const isSelected = option === value;
      const optionName = getYesNoSomewhatText(option);

      return (
        <PillButton
          key={optionName}
          onChange={() => onChange(isSelected ? null : option)}
          className={clsx(
            isSelected ? [selectedStyle, getOptionColor(option)] : defaultStyle
          )}
          name={
            <div className={clsx(!isSelected && "border border-white")}>
              {optionName}
            </div>
          }
        />
      );
    })}
    {required && (
      <span className="text-gray-800 text-sm -ml-[3px] mb-[4px] font-semibold">
        *
      </span>
    )}
  </div>
);

const getOptionColor = (option: YesNoSomewhat) => {
  switch (option) {
    case YesNoSomewhat.Yes:
      return "border-emerald-600 bg-emerald-500 bg-opacity-90 text-white";
    case YesNoSomewhat.Somewhat:
      return "border-amber-600 bg-amber-500 bg-opacity-90 text-white";
    case YesNoSomewhat.No:
      return "border-rose-600 bg-rose-500 bg-opacity-80 text-white";
    default:
      assertUnreachable(option, "YesNoSomewhat Option");
  }
};
