import clsx from "clsx";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

type Props = {
  toggleState: boolean;
  handleToggle: (toggleState: boolean) => void;
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export const ToggleButton = ({
  handleToggle,
  toggleState,
  className = "",
  tooltip,
  label,
  disabled,
}: Props) => (
  <div className={clsx("inline-flex items-center mr-[3px]", className)}>
    {label && <span className="mr-[6px]">{label}</span>}
    {tooltip && (
      <Tooltip
        className={clsx(
          disabled ? "text-red-500" : " text-blue-600",
          "w-5 h-5 text-blue-600 mr-1 cursor-help"
        )}
        content={
          <div className="block w-48 text-sm text-center">{tooltip}</div>
        }
        tooltipProps={{ place: "bottom" }}
      />
    )}
    <div
      className={clsx(
        "relative w-[42px] h-[22px] rounded-full",
        "transition duration-300 ease-in-out border border-slate-300",
        toggleState ? "bg-blue-500" : "bg-slate-200",
        disabled
          ? toggleState
            ? "!bg-blue-900/50"
            : "!bg-slate-200/80"
          : "cursor-pointer"
      )}
      onClick={() => {
        if (disabled) return;
        handleToggle(!toggleState);
      }}
    >
      {/* Circle Indicator */}
      <div
        className={clsx(
          "flex items-center justify-center bg-white",
          "relative w-[18px] h-[18px] rounded-full top-[1px]",
          "transition-all duration-300 ease-in-out",
          toggleState ? "bg-blue-500 left-[21px]" : "left-[1px]"
        )}
      >
        {disabled && (
          <Icon
            icon="disabled"
            size={4}
            color="text-slate-300"
            className="pr-[0.5px]"
          />
        )}
      </div>
    </div>
  </div>
);
