import { useId } from "react";
import { FaLock } from "react-icons/fa";
import { NoteStatusIndicator } from "../NoteStatusIndicator";
import { Tooltip } from "../Tooltip";

type Props = {
  value?: string | null;
  onChange: (note: string | null) => void;
  debouncePending: boolean;
  mutationLoading: boolean;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
};

export function StickyNoteInput({
  value,
  onChange,
  debouncePending,
  mutationLoading,
  placeholder,
  rows = 1,
  disabled,
}: Props) {
  const id = useId();

  return (
    <div className="stickynote">
      <label htmlFor={id} className="sr-only">
        Add a note
      </label>
      <textarea
        rows={rows}
        name="note"
        id={id}
        className="sm:text-sm"
        placeholder={placeholder}
        value={value ?? ""}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      />
      <div className="absolute bottom-1 right-1">
        {disabled ? (
          <Tooltip content="This note is read-only.">
            <FaLock className="w-5 h-5 text-yellow-900 opacity-20" />
          </Tooltip>
        ) : (
          <NoteStatusIndicator
            debouncePending={debouncePending}
            mutationLoading={mutationLoading}
          />
        )}
      </div>
    </div>
  );
}
