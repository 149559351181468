import { ClipboardCopyIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

export function MinimalCopyButton({
  copyValue,
  label = "Copy",
}: {
  copyValue: string;
  label?: string;
}) {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (showSuccess) {
      const timerId = setTimeout(() => setShowSuccess(false), 1000);
      return () => clearTimeout(timerId);
    }
  }, [showSuccess]);

  return (
    <button
      className="flex items-center"
      data-bs-toggle="tooltip"
      onClick={() => {
        navigator.clipboard.writeText(copyValue);
        setShowSuccess(true);
      }}
    >
      {showSuccess ? (
        <CheckCircleIcon className="h-5 w-5 text-green-600" />
      ) : (
        <ClipboardCopyIcon className="h-5 w-5 text-gray-500" />
      )}

      <span className="ml-1 text-xs text-gray-500">{label}</span>
    </button>
  );
}
