import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";
import { getCohortSessionStudentAttendanceStatusText } from "@utils/labels";
import clsx from "clsx";
import { PillButton } from "../Buttons/PillButton";
import { Spinner } from "../Loading/Spinner";
import { getAttendanceStatusStyles } from "./utils";

type Props = {
  loading?: boolean;
  isSelected?: boolean;
  status: CohortSessionStudentAttendanceStatus;
  updateStudentAttendance?: (
    status: CohortSessionStudentAttendanceStatus
  ) => void;
};

export const AttendanceStatusPillButton = ({
  status,
  loading = false,
  isSelected = true,
  updateStudentAttendance,
}: Props) => (
  <PillButton
    key={status}
    className={clsx(
      "relative border",
      isSelected
        ? `${getAttendanceStatusStyles(status).pillColor} border-2 text-white`
        : "border-gray-300 bg-white text-gray-700"
    )}
    name={
      <div className={clsx(!isSelected && "border border-white")}>
        {loading && (
          <Spinner
            color="text-blue-700"
            size={4}
            className="absolute right-[calc(50%-8px)]"
          />
        )}
        <div className={clsx(loading && "opacity-0")}>
          {getCohortSessionStudentAttendanceStatusText(status)}
        </div>
      </div>
    }
    onChange={() =>
      updateStudentAttendance?.(
        isSelected ? CohortSessionStudentAttendanceStatus.Unknown : status
      )
    }
  />
);
