import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
} from "@generated/graphql";
import {
  CohortAssignmentSubSubjectTextMode,
  CohortAssignmentSubjectTextMode,
  getCohortSubSubjectText,
  getCohortSubjectText,
} from "@utils/labels";
import { makeCacheKey } from "@utils/strings";
import { assertUnreachable } from "@utils/types";
import {
  elaSubSubjectOptions,
  generalSubSubjectOptions,
  mathSubSubjectOptions,
  scienceSubSubjectOptions,
  socialScienceSubSubjectOptions,
} from "./constants";
import {
  ANY_SUB_SUBJECT,
  StudentAttendanceStyleData,
  SubSubjectAllOption,
  SubSubjectOption,
  SubSubjectOrAll,
  SubjectComboKey,
  SubjectComboWithAll,
} from "./types";

export const isNotAttended = (
  status?: CohortSessionStudentAttendanceStatus
): boolean =>
  !status ||
  status === CohortSessionStudentAttendanceStatus.Unknown ||
  status === CohortSessionStudentAttendanceStatus.Absent;

export const negOneIsNull = (num?: number | null) => (num === -1 ? null : num);

export const makeAttendanceKey = (
  cohortSessionId: number | string,
  studentId: number | string
) => makeCacheKey(cohortSessionId, studentId);

export const getSubjectSubSubjectOptions = (
  subject: CohortAssignmentSubject
) => {
  switch (subject) {
    case CohortAssignmentSubject.Math:
      return mathSubSubjectOptions;
    case CohortAssignmentSubject.Ela:
      return elaSubSubjectOptions;
    case CohortAssignmentSubject.Science:
      return scienceSubSubjectOptions;
    case CohortAssignmentSubject.SocialStudies:
      return socialScienceSubSubjectOptions;
    case CohortAssignmentSubject.General:
      return generalSubSubjectOptions;
    default:
      assertUnreachable(subject, "subject");
  }
};

function createSubSubjectOptions(
  subSubjects: CohortAssignmentSubSubject[],
  selectedLabelMode?: CohortAssignmentSubSubjectTextMode
): SubSubjectOption[] {
  return subSubjects.map((subSubject) => ({
    id: subSubject,
    value: getCohortSubSubjectText(subSubject),
    subSubject,
    ...(selectedLabelMode && {
      selectedLabel: getCohortSubSubjectText(subSubject, selectedLabelMode),
    }),
  }));
}

export function getSubSubjectOptions(
  subject?: CohortAssignmentSubject,
  selectedLabelMode?: CohortAssignmentSubSubjectTextMode
): SubSubjectOption[] {
  if (!subject) return [];
  const subSubjects = getSubjectSubSubjectOptions(subject);
  return createSubSubjectOptions(subSubjects, selectedLabelMode);
}

export function getSubSubjectAllOptions(
  subject?: CohortAssignmentSubject,
  selectedLabelMode?: CohortAssignmentSubSubjectTextMode
): SubSubjectAllOption[] {
  if (!subject) return [];
  const subSubjects = getSubjectSubSubjectOptions(subject);
  return [
    {
      id: ANY_SUB_SUBJECT,
      value: ANY_SUB_SUBJECT,
      subSubject: ANY_SUB_SUBJECT,
    },
    ...createSubSubjectOptions(subSubjects, selectedLabelMode),
  ];
}

export const getSubjectComboKey = (
  subjectCombo: SubjectComboWithAll
): SubjectComboKey => `${subjectCombo.subject}-${subjectCombo.subSubject}`;

export const getSubjectComboFromKey = (combo: string) => {
  const [subject, subSubject] = combo.split("-") as [
    CohortAssignmentSubject,
    SubSubjectOrAll,
  ];
  return { subject, subSubject };
};

export const getSubjectNameText = (
  subjectCombo: SubjectComboWithAll,
  mode?: CohortAssignmentSubjectTextMode
) => {
  const { subject, subSubject } = subjectCombo;
  if (subSubject === CohortAssignmentSubSubject.General)
    return `${getCohortSubjectText(subject, mode ?? "long")} (General)`;
  if (subSubject === ANY_SUB_SUBJECT)
    return `${getCohortSubjectText(subject, mode ?? "long")}`;
  return getCohortSubSubjectText(subSubject);
};

export const getSubjectAllCombo = (
  subject: CohortAssignmentSubject
): SubjectComboWithAll => ({
  subject,
  subSubject: ANY_SUB_SUBJECT,
});

export const getAttendanceStatusStyles = (
  status: CohortSessionStudentAttendanceStatus
): StudentAttendanceStyleData => {
  switch (status) {
    case CohortSessionStudentAttendanceStatus.Present:
      return {
        peepColor: "text-emerald-500",
        iconType: "check",
        pillColor: "bg-emerald-500 border-emerald-600 bg-opacity-90",
      };
    case CohortSessionStudentAttendanceStatus.Partial:
      return {
        peepColor: "text-amber-500",
        iconType: "alarm",
        pillColor: "bg-amber-500 border-amber-600 bg-opacity-90",
      };
    case CohortSessionStudentAttendanceStatus.Absent:
      return {
        peepColor: "text-rose-500",
        iconType: "cross",
        pillColor: "bg-rose-500 border-rose-600 bg-opacity-80",
      };

    case CohortSessionStudentAttendanceStatus.Unknown:
      return {
        peepColor: "text-gray-800",
        iconType: "question",
        pillColor: "bg-gray-950 border-slate-950 bg-opacity-60",
      };
    default:
      assertUnreachable(status, "status");
  }
};
