import { Route } from "@utils/routes/types";
import clsx from "clsx";
import React from "react";
import { assertUnreachable } from "utils/types";
import { Link } from "../Link";
import { Spinner } from "../Loading/Spinner";

export type ThemeT =
  | "primary"
  | "secondary"
  | "tertiary"
  | "slate"
  | "dark"
  | "success"
  | "danger"
  | "light";

export type ButtonHeight = "xs" | "sm" | "md" | "lg";

type Props = {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  theme?: ThemeT;
  loading?: boolean;
  height?: ButtonHeight;
};

const ButtonForwardRef = (
  {
    type = "button",
    onClick,
    disabled = false,
    children,
    className,
    theme = "primary",
    loading = false,
    height = "md",
  }: Props,
  ref: React.Ref<HTMLButtonElement>
) => (
  <button
    className={clsx(
      getOuterCommonStyles(theme),
      disabled ? `opacity-50 cursor-not-allowed` : "cursor-pointer",
      loading && "text-transparent",
      "shadow-button-outer relative",
      getHeight(height),
      className
    )}
    type={type}
    onClick={onClick}
    disabled={disabled}
    ref={ref}
  >
    <div
      className={clsx(
        "absolute w-full h-full left-0 top-0 rounded-md",
        getStyle(theme).innerShadow
      )}
    />

    {children}
    <div
      className={clsx(
        loading ? "absolute" : "hidden",
        "top-1/2 left-1/2 transition-none transform -translate-x-1/2 -translate-y-1/2"
      )}
    >
      <Spinner color={getStyle(theme).spinnerColor} />
    </div>
  </button>
);

ButtonForwardRef.displayName = "Button";

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ButtonForwardRef
);

type ButtonLinkProps = {
  href?: string;
  theme?: ThemeT;
  height?: ButtonHeight;
  className?: string;
  route?: Route;
  routeProps?: string[];
  openInNewTab?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
};

export const ButtonLink = ({
  href,
  children,
  className,
  route,
  height = "md",
  routeProps,
  theme = "primary",
  openInNewTab = false,
  disabled = false,
}: ButtonLinkProps) => (
  <Link
    href={href}
    route={route}
    routeProps={routeProps}
    target={openInNewTab ? "_blank" : undefined}
  >
    <Button
      disabled={disabled}
      theme={theme}
      height={height}
      className={className}
    >
      {children}
    </Button>
  </Link>
);

function getStyle(theme: ThemeT) {
  switch (theme) {
    case "primary":
      return {
        colors: "bg-blue-600 hover:bg-blue-700 group-hover:bg-blue-700",
        shadow: "shadow-button-outer",
        text: "text-white text-sm font-medium",
        spinnerColor: "text-white",
        innerShadow: "shadow-button-inner",
      };
    case "secondary":
      return {
        colors: "bg-blue-100 hover:bg-blue-200 group-hover:bg-blue-200 ",
        shadow: "shadow-button-outer",
        text: "text-blue-700 text-sm font-medium",
        spinnerColor: "text-blue-700",
        innerShadow: "shadow-button-inner-light",
      };
    case "tertiary":
      return {
        colors: "bg-white hover:bg-gray-100 group-hover:bg-gray-100 ",
        shadow: "shadow-button-outer",
        text: "text-gray-700 text-sm font-medium",
        spinnerColor: "text-gray-700",
        innerShadow: "shadow-button-inner-light",
      };
    case "slate":
      return {
        colors: "bg-slate-600 hover:bg-slate-800 group-hover:bg-slate-800 ",
        shadow: "shadow-button-outer",
        text: "text-white text-sm font-medium inner-lg",
        spinnerColor: "text-white",
        innerShadow: "shadow-button-inner",
      };
    case "success":
      return {
        colors:
          "bg-emerald-500 hover:bg-emerald-600 group-hover:bg-emerald-600 ",
        shadow: "shadow-button-outer",
        text: "text-white text-sm font-medium",
        spinnerColor: "text-white",
        innerShadow: "shadow-button-inner",
      };
    case "danger":
      return {
        colors: "bg-rose-500 hover:bg-rose-600 group-hover:bg-rose-600 ",
        shadow: "shadow-button-outer",
        text: "text-white text-sm font-medium",
        spinnerColor: "text-white",
        innerShadow: "shadow-button-inner",
      };
    case "dark":
      return {
        colors: "bg-slate-900 hover:bg-black group-hover:bg-black",
        shadow: "shadow-button-outer",
        text: "text-white text-sm font-medium inner-lg",
        spinnerColor: "text-white",
        innerShadow: "shadow-button-inner",
      };
    case "light":
      return {
        colors: "bg-slate-400 hover:bg-slate-700 group-hover:bg-slate-700 ",
        shadow: "shadow-button-outer",
        text: "text-white text-sm font-medium inner-lg",
        spinnerColor: "text-white",
        innerShadow: "shadow-button-inner-light",
      };
    default:
      return assertUnreachable(theme, "ThemeT");
  }
}

const getHeight = (height: ButtonHeight) => {
  switch (height) {
    case "xs":
      return "h-[28px]";
    case "sm":
      return "h-[34px]";
    default:
    case "md":
      return "h-[38px]";
    case "lg":
      return "h-[42px]";
  }
};

const getOuterCommonStyles = (theme: ThemeT) => {
  const { colors, shadow, text } = getStyle(theme);
  return [
    "relative inline-flex justify-center cursor-pointer items-center",
    "rounded-md px-4 leading-tight",
    colors,
    shadow,
    text,
  ];
};
